import * as React from "react";
import Helmet from "react-helmet";
import { A2, A4, A6, A7, A8 } from "../components/fonts";
import { StaticImage } from "gatsby-plugin-image";
import Container from "../components/container";
import PropTypes from "prop-types";

const PersonListing = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const show = isOpen ? "" : "hidden";
  return (
    <div
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      className={`cursor-pointer pb-6 ${isOpen && ""}`}
    >
      <div className="text-wbStone50 hover:text-wbSand transition duration-300">
        <div className="block md:block pb-3">{props.portrait}</div>

        <A6 className={`leading-none text-wbSand`}>{props.name}</A6>
        <A6 className={`leading-none`}>{props.title}</A6>
        <A8 className={`${show} pt-3 max-w-sm`}>{props.bio}</A8>
      </div>
    </div>
  );
};

PersonListing.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  portrait: PropTypes.object,
  bio: PropTypes.string,
};

const AboutPage = () => {
  return (
    <>
      <Helmet title="Home" />
      <Container className="bg-wbTruss">
        <div className="col-span-30 mt-32 px-6">
          <A2 className="pt-10 max-w-2xl xl:max-w-4xl text-wbStone50">
            There are too many gaps in the modern supply chain.
          </A2>
          <A2 className="pt-10 max-w-2xl xl:max-w-4xl text-wbStone50">
            We aim to bridge them — to make the world more efficient, resilient,
            and sustainable.
          </A2>
          <A2 className="pt-10 max-w-2xl xl:max-w-4xl text-wbStone50">
            To do so, we&apos;ve assembled a team of experts in commodities,
            logistics, and technology.
          </A2>
          <A2 className="pt-10 max-w-2xl xl:max-w-4xl text-wbStone50">
            To meld knowledge.
          </A2>
          <A2 className="pt-10 max-w-2xl xl:max-w-4xl text-wbStone50">
            To lead with context.
          </A2>
          <A2 className="pt-10 pb-20 max-w-2xl xl:max-w-none text-wbSand">
            To drive positive transformation, together.
          </A2>
        </div>
        <div className="col-span-30 md:col-span-10 xl:col-span-15 px-6 mt-16">
          <A4 className="text-wbSand pt-3 border-t border-wbSand">
            Leadership
          </A4>
          <A7 className="text-wbSand mt-16 max-w-xxs">
            We&apos;re a combination of expertise in commodities, technology,
            and innovation.
          </A7>
        </div>
        <div className="col-span-30 md:col-span-20 xl:col-span-15 px-6 mt-16">
          <div className="grid grid-cols-2 gap-x-6 md:grid-cols-3">
            <PersonListing
              name="Scott Evans"
              title="Advisory Board Member"
              bio="Understands first-hand the frustrations and distinct complexities of the physical commodities market from 20+ years of industry experience. Previously, Scott built the metals trading desk at Goldman Sachs and at Concord Resources Limited, where he was co-founder."
              portrait={
                <StaticImage
                  imgStyle={{ borderRadius: "0.25rem" }}
                  src="../images/people/scott.png"
                  alt=""
                />
              }
            />
            <PersonListing
              name="Andrea Aranguren"
              title="Chief Executive Officer"
              bio="10 years of experience in commodities (oil & gas, metals, and environmental) operations and in helping startups to optimize their logistics & manage their supply chains. Previously, Andrea was VP of Logistics at Goldman Sachs."
              portrait={
                <StaticImage
                  imgStyle={{ borderRadius: "0.25rem" }}
                  src="../images/people/andrea.png"
                  alt=""
                />
              }
            />
            <PersonListing
              name="Kinney Sheth"
              title="VP of Design"
              bio="Design strategist with experience leading product and brand design at several prominent NYC agencies. Previously, Kinney was Head of Design at Chief, a startup founder, and named in Business Insider's Women Entrepreneurs Under 30 to Watch."
              portrait={
                <StaticImage
                  imgStyle={{ borderRadius: "0.25rem" }}
                  src="../images/people/kinney.png"
                  alt=""
                />
              }
            />
            <PersonListing
              name="Laura Braswell"
              title="Head of Product"
              bio="Laura Braswell has 8 years of experience managing products in B2B SaaS with a focus on tools that transform supply chains. She has been   part of building the trade management platform from early pilot stages to its current form. Previously, Laura spent more than 5 years working in product for a supply chain finance company."
              portrait={
                <StaticImage
                  imgStyle={{ borderRadius: "0.25rem" }}
                  src="../images/people/laura.png"
                  alt=""
                />
              }
            />
            <PersonListing
              name="Ali Cigari"
              title="VP Engineering and Data Science"
              bio="Commodities strategist with 8 years of experience in coal, oil, and metals, tackling problems in physical and financial trading, funding and credit. Former VP at Goldman Sachs, and consultant at various commodity trading houses."
              portrait={
                <StaticImage
                  imgStyle={{ borderRadius: "0.25rem" }}
                  src="../images/people/ali.png"
                  alt=""
                />
              }
            />
            <PersonListing
              name="Joe Hsu"
              title="Engineering Manager"
              bio="Joe is an experienced egineer with a track record of success spanning over a decade. He has specialized in developing applications for diverse industries, including education admissions, platform as a service, advertising technology, and the commodity sector. Joe's collaborative approach, combined with his problem-solving skills, enables him to work closely with product managers and designers to deliver exceptional customer experiences. With a passion for innovation, Joe continuously strives to create cutting-edge solutions that meet the evolving demands of the industry."
              portrait={
                <StaticImage
                  imgStyle={{ borderRadius: "0.25rem" }}
                  src="../images/people/joe.png"
                  alt=""
                />
              }
            />
          </div>
        </div>
        <div className="col-span-30 md:col-span-10 xl:col-span-15 mt-32 px-6">
          <A4 className="text-wbSand pt-3 border-t border-wbSand">Values</A4>
          <A7 className="text-wbSand mt-16 max-w-xxs">
            These concepts echo through our digital hallways. They are the
            guiding light for what it means to work at Waybridge.
          </A7>
        </div>
        <div className="col-span-30 md:col-span-20 xl:col-span-15 px-6 mt-32">
          <div className="flex flex-row border-t border-wbSand pt-3 pb-12">
            <A7 className="text-wbSand w-full md:w-4/12">
              Drive positive transformation.
            </A7>
            <A7 className="text-wbSand w-full md:w-8/12">
              We drive systemic, positive transformation for our customers,
              within the Waybridge community and in the ecosystems and
              communities where we operate.
            </A7>
          </div>
          <div className="flex flex-row border-t border-wbSand pt-3 pb-12">
            <A7 className="text-wbSand w-full md:w-4/12">Lead with context.</A7>
            <A7 className="text-wbSand w-full md:w-8/12">
              We are transparent and inclusive when we make and explain
              decisions. We are all leaders.
            </A7>
          </div>
          <div className="flex flex-row border-t border-wbSand pt-3 pb-12">
            <A7 className="text-wbSand w-full md:w-4/12">
              Act with agency and responsibility.
            </A7>
            <A7 className="text-wbSand w-full md:w-8/12">
              We take pride in our work, and take responsibility for its impact.
              We are empowered to take action to move forward.
            </A7>
          </div>
          <div className="flex flex-row border-t border-wbSand pt-3 pb-12">
            <A7 className="text-wbSand w-full md:w-4/12">Foster connection.</A7>
            <A7 className="text-wbSand w-full md:w-8/12">
              We learn from and teach each other to build connections and
              amplify outcomes of our collective efforts. We create partnerships
              internally and externally.
            </A7>
          </div>
          <div className="flex flex-row border-t border-wbSand pt-3 pb-12">
            <A7 className="text-wbSand w-full md:w-4/12">Build trust.</A7>
            <A7 className="text-wbSand w-full md:w-8/12">
              We listen, speak and give candid feedback. We treat each other
              with respect. We collaborate — with intention and care.
            </A7>
          </div>
        </div>
      </Container>
    </>
  );
};
export default AboutPage;
